<template>
  <div class="examination-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-select v-model="searchModel.engineeringType" placeholder="工程类型" clearable>
            <el-option
              v-for="engineeringType in engineeringTypes"
              :key="engineeringType.dictionaryGuid"
              :label="engineeringType.dictionaryValue"
              :value="engineeringType.dictionaryValue"
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchModel.tenderName"
            placeholder="标段名称"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchModel.periodName"
            placeholder="工期"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchModel.approvalStatus" placeholder="审批状态">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="未最终批复" :value="1"></el-option>
            <el-option label="最终批复" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button v-if="showAddBtn" type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <my-el-table
      :data="examinationList"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      :myTableHeight="myTableHeight"
    >
      <el-table-column prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="tenderName" width="60" header-align="center" align="center" label="标段"></el-table-column>
      <el-table-column prop="constructionUnit" header-align="center" align="center" label="承建单位"></el-table-column>
      <el-table-column prop="periodName" header-align="center" width="60" align="center" label="期数"></el-table-column>
      <el-table-column prop="examinationCode" width="150" header-align="center" align="center" label="申报编号"></el-table-column>
      <el-table-column prop="createStaffName" width="150" header-align="center" align="center" label="申报人"></el-table-column>
      <el-table-column prop="approvalUserName" width="150" header-align="center" align="center" label="审核人"></el-table-column>
      <el-table-column prop="approvalStatus" width="150" header-align="center" align="center" label="审核状态"></el-table-column>
      <el-table-column prop="" width="100" header-align="center" align="center" label="过程数据">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <a @click="handleChartClick(scope.row)" class="mr-2" href="#">
            <el-tooltip class="item" effect="light" content="流程图" :open-delay="500" placement="top">
              <img src="@/assets/images/svg/flowchart.svg" alt="">
            </el-tooltip>
          </a>
          <a @click="handleAttachmentClick(scope.row)" href="#">
            <el-tooltip class="item" effect="light" content="附件" :open-delay="500" placement="top">
              <img src="@/assets/images/svg/attachment.svg" alt="">
            </el-tooltip>
          </a>
        </template>
      </el-table-column>
      <el-table-column width="220" header-align="center" align="center" label="操作">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <a @click="handleDetailClick(scope.row)" class="mr-2" href="#">
            <el-tooltip class="item" effect="light" content="明细" :open-delay="500" placement="top">
              <img src="@/assets/images/svg/detail.svg" alt="">
            </el-tooltip>
          </a>
          <a @click="handleReportClick(scope.row)" class="mr-2" href="#">
            <el-tooltip class="item" effect="light" content="数据汇总" :open-delay="500" placement="top">
              <img style="width: 18px; height: 18px;" src="@/assets/images/svg/summary.svg" alt="">
            </el-tooltip>
          </a>
          <a v-if="finalSubmitShow" @click="handleFinalSubmitClick(scope.row, 1)" class="mr-2" href="#">
            <el-tooltip class="item" effect="light" content="最终批复" :open-delay="500" placement="top">
              <img style="width: 18px; height: 18px;" src="@/assets/images/svg/approval.svg" alt="">
            </el-tooltip>
          </a>
          <a v-if="finalSubmitShow" @click="handleFinalSubmitClick(scope.row, 0)" class="mr-2" href="#">
            <el-tooltip class="item" effect="light" content="取消最终批复" :open-delay="500" placement="top">
              <img src="@/assets/images/svg/cancelapproval.svg" alt="">
            </el-tooltip>
          </a>
          <a @click="handleDeleteClick(scope.row)" class="" href="#">
            <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
              <i style="transform: translateY(1px);" class="text-danger far fa-trash-alt operation-button"></i>
            </el-tooltip>
          </a>
        </template>
      </el-table-column>
      <el-table-column v-if="isAdmin" width="160" align="center" header-align="center" label="管理员功能">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <a class="mr-2" href="#" @click="handleFinancialEstimateClick(scope.row)">
            生成概算
          </a>
          <a href="#" @click="handleReportAdminClick(scope.row)">数据汇总</a>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <examination-form
        ref="examinationForm"
        :examinationModel="examinationModel">
      </examination-form>
    </my-dialog>

    <my-dialog
        ref="wechatDialog"
        title="微信推送"
        size="md"
        ok-text="确定"
        cancel-text="取消"
        @ok-click="doPush"
      >
      <el-form class="text-center">
        <el-form-item prop="">
          <el-select v-model="pushUsers" multiple clearable>
            <el-option
              v-for="user of userList"
              :key="user.userGuid"
              :label="user.staffName"
              :value="user.userGuid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <input type="checkbox" v-model="isGroup">是否按分组推送
        </el-form-item>
      </el-form>
    </my-dialog>

    <my-dialog
      ref="submitFormDialog"
      title="传送"
      size="md"
      ok-text="确定"
      cancel-text="取消"
      @ok-click="doSubmit"
      >
      <el-form class="text-center">
        <el-form-item>
          <el-cascader
            v-if="identityAndUsers.length"
            ref="myCascader"
            :options="identityAndUsers"
            clearable>
          </el-cascader>
        </el-form-item>
      </el-form>
    </my-dialog>

    <my-dialog
      ref="attachmentDialog"
      size="xl"
      title="附件列表"
      cancel-text="关闭"
      :hide-ok="true"
      >
      <el-form inline>
        <el-form-item>
          <el-upload
            class="upload-attachment"
            ref="upload"
            :action="uploadAction"
            :on-remove="handleRemove"
            :on-success="handleSuccess"
            :on-error="handleError"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :data="uploadData"
            :auto-upload="false">
            <el-button slot="trigger" size="small" type="primary">选取附件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-select v-model="attachmentType">
            <el-option v-for="attachmentType of attachmentTypes" :key="attachmentType.dictionaryGuid" :label="attachmentType.dictionaryValue" :value="attachmentType.sortId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleBeforeUpload">上传</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="attachmentList"
        row-key="AttachmentGuid"
        border
        header-cell-class-name="bg-info text-light"
        >
        <el-table-column align="left" prop="OriginalFileName" header-align="center" label="文件名"></el-table-column>
        <el-table-column align="center" prop="FilesType" header-align="center" label="文件类型"></el-table-column>
        <el-table-column align="center" prop="FileSize" header-align="center" label="文件大小"></el-table-column>
        <el-table-column align="center" :formatter="timeFormatter" prop="CreateTime" header-align="center" label="上传时间"></el-table-column>
        <el-table-column header-align="center" align="center" width="100" label="操作">
          <template v-if="scope.row.detailRow" slot-scope="scope">
            <a :href="reportPrefix + scope.row.SavePath" target="_blank" :download="scope.row.OriginalFileName" class="mr-2">下载</a>
            <a href="#" @click="handleAttachmentDeleteClick(scope.row)">删除</a>
          </template>
        </el-table-column>
      </el-table>
    </my-dialog>

    <my-dialog
      ref="chartDialog"
      title="流程图"
      cancel-text="关闭"
      :hide-ok="true"
      >
      <examination-timeline
        :examinationGuid="examinationModel.examinationGuid">
      </examination-timeline>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import MyElTable from '@/components/MyElTable/MyElTable'
import ExaminationForm from '@/views/ExaminationManage/ExaminationForm'
import ExaminationModel from '@/model/ExaminationModel'
import examinationService from '@/services/examinationService'
import dictionaryService from '@/services/dictionaryService'
import PageInfo from '@/support/PageInfo'
import PageOperation from '@/mixins/PageOperation'
import config from '@/config'
import ExaminationTimeline from '@/views/ExaminationManage/ExaminationTimeline'
import auth from '@/common/auth'
import utility from '@/common/utility'
import { mapMutations } from 'vuex'
import axios from 'axios'
import userService from '@/services/userService'

export default {
  name: 'ExaminationIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    MyElTable,
    ExaminationTimeline,
    ExaminationForm
  },
  data () {
    return {
      isGroup: false,
      total: 0,
      examinationList: [],
      examinationModel: new ExaminationModel(),
      identityAndUsers: [],
      engineeringTypes: [],
      formDialogTitle: '',
      searchModel: {
        engineeringType: '',
        tenderName: '',
        periodName: '',
        approvalStatus: 0
      },
      uploadData: {},
      fileList: [],
      uploadAction: `${config.reportHost}/UploadFile`,
      attachmentList: [],
      userList: [],
      pushUsers: [],
      attachmentTypes: [],
      attachmentType: null
    }
  },
  computed: {
    isAdmin () {
      if (config.proIdentities.indexOf(auth.getUserInfo().identityGuid) !== -1) {
        return true
      }
      return false
    },
    myTableHeight () {
      return document.body.clientHeight - 200
    },
    showAddBtn () {
      return config.labIdentities.indexOf(auth.getUserInfo().identityGuid) !== -1
    },
    finalSubmitShow () {
      let identityGuid = auth.getUserInfo().identityGuid
      return config.finalSubmitIdentities.indexOf(identityGuid) !== -1
    },
    reportPrefix () {
      return config.reportHost + '/' + config.reportFolder
    }
  },
  methods: {
    handlePush (row) {
      this.pushUsers = []
      this.examinationModel = row
      examinationService.getPushData(this.examinationModel.examinationGuid)
        .then(res => {
          let result = res.data.data[0]
          this.examinationModel.currentPeriodAmount = parseFloat(result.benQiZhiFu).toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
          this.examinationModel.contractAmount = parseFloat(result.heTongJinE).toFixed(0).replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, '$&,')
          this.examinationModel.projectName = result.projectName
          this.$refs.wechatDialog.open()
        })
        .catch(err => {
          console.log(err)
        })
    },
    doPush () {
      if (this.isGroup) {
        this.doPushGroupId()
      } else {
        this.doPushOpenId()
      }
    },
    doPushGroupId () {
      if (this.pushUsers.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择推送人员！'
        })
        return
      }
      let openIds = this.userList.filter(user => this.pushUsers.find(item => item === user.userGuid)).map(user => user.groupID).filter(groupID => !!groupID)
      openIds = openIds.filter((item, index) => openIds.indexOf(item, 0) === index)
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.examinationModel.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()
      let url = `http://meterage.ljkjkf.com:5002/report?format=png&inline=true&period=${this.examinationModel.periodGuid}&report=3731e820-baa1-11ea-9277-3b69d68a7656&timestamp=${timestamp}&parameter=${token}&pageindex=0&pagesize=0`
      axios.post('https://mp.ljkjkf.com/api/templateMessage/meterageNotify', {
        openIds: openIds.join(','),
        url,
        projectName: this.examinationModel.projectName,
        tenderName: this.examinationModel.tenderName,
        periodName: `第${this.examinationModel.periodName}期`,
        contractBalance: this.examinationModel.contractAmount + '元',
        currentPeriodPayment: this.examinationModel.currentPeriodAmount + '元',
        remark: '查看详情'
      }).then(res => {
        if (parseInt(res.data.errcode) === 0) {
          this.$message({
            type: 'success',
            message: '推送成功！'
          })
          this.pushUsers = []
          this.$refs.wechatDialog.close()
        } else {
          this.$message({
            type: 'error',
            message: '推送失败，请重试！'
          })
        }
      }).catch(err => {
        console.log(err)
        this.$message({
          type: 'error',
          message: '操作失败，请重试！'
        })
      })
    },
    doPushOpenId () {
      if (this.pushUsers.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择推送人员！'
        })
        return
      }
      const openIds = this.userList.filter(user => this.pushUsers.find(item => item === user.userGuid)).map(user => user.openID).filter(openID => !!openID)
      console.log('openIds', openIds)
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.examinationModel.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()
      let url = `http://meterage.ljkjkf.com:5002/report?format=png&inline=true&period=${this.examinationModel.periodGuid}&report=3731e820-baa1-11ea-9277-3b69d68a7656&timestamp=${timestamp}&parameter=${token}&pageindex=0&pagesize=0`
      axios.post('https://mp.ljkjkf.com/api/templateMessage/meterageNotify', {
        openIds: openIds.join(','),
        url,
        projectName: this.examinationModel.projectName,
        tenderName: this.examinationModel.tenderName,
        periodName: `第${this.examinationModel.periodName}期`,
        contractBalance: this.examinationModel.contractAmount + '元',
        currentPeriodPayment: this.examinationModel.currentPeriodAmount + '元',
        remark: '查看详情'
      }).then(res => {
        if (parseInt(res.data.errcode) === 0) {
          this.$message({
            type: 'success',
            message: '推送成功！'
          })
          this.pushUsers = []
          this.$refs.wechatDialog.close()
        } else {
          this.$message({
            type: 'error',
            message: '推送失败，请重试！'
          })
        }
      }).catch(err => {
        console.log(err)
        this.$message({
          type: 'error',
          message: '操作失败，请重试！'
        })
      })
    },
    handleBeforeUpload () {
      if (!this.attachmentType) {
        this.$alert('请选择附件类别！')
        return
      }
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.examinationModel.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.uploadAction = `${config.reportHost}/UploadFile?date=${utility.getCurrentTime(8)}&isanalysi=0&examination=${this.examinationModel.examinationGuid}&userguid=${auth.getUserInfo().userGuid}&timestamp=${timestamp}&parameter=${token}&Category=${this.attachmentType}`

      this.uploadData = {
        date: utility.getCurrentTime(8),
        isanalysi: 0,
        examination: this.examinationModel.examinationGuid,
        userguid: auth.getUserInfo().userGuid,
        timestamp: timestamp,
        parameter: token
      }

      this.$nextTick(() => {
        this.$refs.upload.submit()
      })

      return false
    },
    handleRemove () {
    },
    handleSuccess () {
      this.$nextTick(() => {
        this.fileList = []
      })
      this.getAttachmentList()

      this.$message({
        type: 'success',
        message: '上传成功！'
      })
    },
    handleError () {
      this.$message({
        type: 'error',
        message: '上传失败！'
      })
    },
    getAttachmentList () {
      let timestamp = utility.getCurrentTimestamp()
      let token = utility.getMd5(`${this.examinationModel.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

      this.attachmentList = []
      axios.get(`${config.reportHost}/GetFileList`, {
        params: {
          examination: this.examinationModel.examinationGuid,
          userguid: this.examinationModel.createUserGuid,
          timestamp: timestamp,
          parameter: token
        }
      }).then(res => {
        if (res.data.Code === 1) {
          this.attachmentList = this.attachmentTypes.map(type => {
            type.AttachmentGuid = utility.getUuid()
            type.OriginalFileName = type.dictionaryValue
            type.children = res.data.Msg.filter(item => item.Category === type.sortId).map(item => {
              item.detailRow = true
              item.FileSize = utility.floatDivide(item.FileSize, 1024).toFixed(2) + ' KB'
              return item
            })
            return type
          })
        } else {
          this.$message({
            type: 'error',
            message: res.data.Msg
          })
        }
      }).catch(err => {
        console.log(err)
        this.$message({
          type: 'error',
          message: '获取附件失败，请重试！'
        })
      })
    },
    handleFinancialEstimateClick (row) {
      this.$confirm(`是否要进行${row.tenderName}标段第${row.periodName}期概算生成？`)
        .then(() => {
          examinationService.makeFinancialEstimate(row.examinationGuid)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '生成概算成功！'
                })
                this._getExaminationList()
                this.$refs.submitFormDialog.close()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '生成概算失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    handleReportClick (row) {
      this.$confirm(`是否要进行${row.tenderName}标段第${row.periodName}期数据汇总？`)
        .then(() => {
          this.$myLoading()
          examinationService.makeReport(row.examinationGuid, auth.getUserInfo().projectGuid, row.tenderGuid, row.periodGuid, row.tenderName, row.periodName)
            .then(res => {
              this.$loading().close()
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '汇总成功！'
                })
                this._getExaminationList()
                this.$refs.submitFormDialog.close()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              this.$loading().close()
              console.log(err)
              this.$message({
                type: 'error',
                message: '汇总失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    handleReportAdminClick (row) {
      this.$confirm(`是否要进行${row.tenderName}标段第${row.periodName}期数据汇总？`)
        .then(() => {
          examinationService.makeReportAdmin(row.examinationGuid, auth.getUserInfo().projectGuid, row.tenderGuid, row.periodGuid, row.tenderName, row.periodName)
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '汇总成功！'
                })
                this._getExaminationList()
                this.$refs.submitFormDialog.close()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '汇总失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    handleAddClick () {
      this.formDialogTitle = '选择工期'
      this.examinationModel = new ExaminationModel()
      this.$refs.formDialog.open()
    },
    handleSearchClick () {
      this._getExaminationList()
    },
    handleSubmitClick (row) {
      this.examinationModel = new ExaminationModel(row)

      this._getNextUsers(this.examinationModel.resourceId)
    },
    doSubmit () {
      let nodes = this.$refs.myCascader.getCheckedNodes()
      if (!nodes.length) {
        this.$message({
          type: 'warning',
          message: '请选择传送对象！'
        })
        return
      }
      let userModel = nodes[0].data
      let flowModel = nodes[0].parent.data

      examinationService.transfer(userModel.userGuid, flowModel.stepName, flowModel.flowGuid, this.examinationModel.examinationGuid, flowModel.sortId)
        .then(res => {
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '传送成功！'
            })
            this._getExaminationList()
            this.$refs.submitFormDialog.close()
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '传送失败！'
          })
        })
    },
    getPushMainMessage (messageDatas) {
      let returnMessage = ''
      if (messageDatas && messageDatas.length) {
        if (messageDatas.filter(message => message.warningType === '合同金额').length) {
          const message = messageDatas.find(message => message.warningType === '合同金额')
          returnMessage += `截至第${message.periodName}期累计计量金额已达到合同总额的${message.endPercentage}%。\n`
        }
        if (messageDatas.filter(message => message.warningType === '开工预付款').length) {
          const message = messageDatas.find(message => message.warningType === '开工预付款')
          returnMessage += `截至第${message.periodName}期累计扣回开工预付款金额已达到开工预付款总额的${message.endPercentage}%。\n`
        }
        if (messageDatas.filter(message => message.warningType === '材料预付款').length) {
          const message = messageDatas.find(message => message.warningType === '材料预付款')
          returnMessage += `截至第${message.periodName}期累计扣回材料预付款金额已达到材料预付款总额的${message.endPercentage}%。`
        }
      }
      return returnMessage
    },
    getPushRemarkMessage (messageDatas) {
      let returnMessage = ''
      if (messageDatas && messageDatas.length) {
        if (messageDatas.filter(message => message.warningType === '合同金额').length) {
          const message = messageDatas.find(message => message.warningType === '合同金额')
          returnMessage += `合同金额：${message.contractAmount}元，清单合计：${message.meterageListAmount}元，累计支付${message.currentPeriodAccumulateAmount}元。`
        }
        if (messageDatas.filter(message => message.warningType === '开工预付款').length) {
          const message = messageDatas.find(message => message.warningType === '开工预付款')
          returnMessage += `开工预付款金额：${message.totalMoney}元，累计扣回开工预付款${message.currentPeriodAccumulateAmount}元。`
        }
        if (messageDatas.filter(message => message.warningType === '材料预付款').length) {
          const message = messageDatas.find(message => message.warningType === '材料预付款')
          returnMessage += `材料预付款金额：${message.totalMoney}元，累计扣回材料预付款${message.currentPeriodAccumulateAmount}元。`
        }
      }
      return returnMessage
    },
    handleFinalSubmitClick (row, flag) {
      const cancelText = (flag === 1 ? '' : '取消')
      this.$confirm(`确定要${cancelText}最终批复吗？`)
        .then(() => {
          this.examinationModel = new ExaminationModel(row)
          examinationService.labFinalSubmit(this.examinationModel.resourceId, flag)
            .then(res => {
              console.log('finalSubmit', res)
              // wechat push
              if (res.data.data && res.data.data.length && !res.data.data[0].openId) {
                // const openIds = this.userList.filter(user => this.pushUsers.find(item => item === user.userGuid)).map(user => user.openID).filter(openID => !!openID)
                // 目前openId是写死的，为了测试使用，且目前也使用个人推送方式，随后应该为按组推送
                const openIds = [ 'oJfYQwPjf4dBX8aW43uEa-7kCMfM' ]
                let timestamp = utility.getCurrentTimestamp()
                let token = utility.getMd5(`${this.examinationModel.tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()
                let url = `http://meterage.ljkjkf.com:5002/report?format=png&inline=true&period=${this.examinationModel.periodGuid}&report=3731e820-baa1-11ea-9277-3b69d68a7656&timestamp=${timestamp}&parameter=${token}&pageindex=0&pagesize=0`
                const pushMessage = res.data.data[0]
                axios.post('http://wechat.ljkjkf.com/sendTemplate', {
                  template_id: 'XDDOZjJVfjVo2-KtF3GCdS1S5l5Vg5rIzxAd_M4ufMQ',
                  url: url,
                  data: {
                    first: {
                      value: `${pushMessage.projectAlias}${pushMessage.tenderName}标段计量支付预警提醒`,
                      color: '#173177'
                    },
                    keyword1: {
                      value: this.getPushMainMessage(res.data.data),
                      color: '#173177'
                    },
                    keyword2: {
                      value: `${utility.getCurrentTime(10)}`,
                      color: '#173177'
                    },
                    remark: {
                      value: this.getPushRemarkMessage(res.data.data),
                      color: '#173177'
                    }
                  }
                }, {
                  params: {
                    openIdsList: openIds.join(',')
                  }
                })
                  .then(res => {
                    if (res.data.errcode === '0') {
                      this.$message({
                        type: 'success',
                        message: '推送成功！'
                      })
                      this.pushUsers = []
                    } else {
                      this.$message({
                        type: 'error',
                        message: '推送失败，请重试！'
                      })
                    }
                  })
                  .catch(err => {
                    console.log(err)
                    this.$message({
                      type: 'error',
                      message: '操作失败，请重试！'
                    })
                  })
              }

              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: `${cancelText}最终批复成功！`
                })
                this._getExaminationList()
                this.$refs.submitFormDialog.close()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: `${cancelText}最终批复失败！`
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    handleAttachmentClick (row) {
      this.examinationModel = new ExaminationModel(row)
      this.attachmentList = []

      this.$refs.attachmentDialog.open()
      this.getAttachmentList()
    },
    handleChartClick (row) {
      this.examinationModel = new ExaminationModel(row)
      this.$refs.chartDialog.open()
    },
    handleDetailClick (row) {
      this.setExaminationGuid(row.examinationGuid)
      this.setTenderGuid(row.tenderGuid)
      this.setPeriodGuid(row.periodGuid)
      this.setTenderName(row.tenderName)
      this.setPeriodName(row.periodName)
      this.setExaminationCode(row.examinationCode)
      this.setApprovalUserGuid(row.approvalUserGuid)
      this.setApprovalStatus(row.approvalStatus)
      this.setApprovalIdentityGuid(row.approvalIdentityGuid)

      this.$router.push('/yaxueExaminationDetail?readonly=false')
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _examinationModel = new ExaminationModel(row)
          examinationService.delete(_examinationModel.resourceId)
            .then(res => {
              console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getExaminationList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.examinationForm.validate()(valid => {
        if (valid) {
          examinationService.add(this.examinationModel.periodGuid)
            .then(res => {
              console.log(res)
              if (res.data.code === 1) {
                this.$refs.formDialog.close()
                this._getExaminationList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '数据加载失败！'
              })
            })
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    handleAttachmentDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let timestamp = utility.getCurrentTimestamp()
          let token = utility.getMd5(`${auth.getUserInfo().tenderGuid}-${timestamp}-${config.fastReportKey}`).toUpperCase()

          axios.post(`${config.reportHost}/DeleteFile?timestamp=${timestamp}&parameter=${token}&AttachmentGuid=${row.AttachmentGuid}&userguid=${auth.getUserInfo().userGuid}`)
            .then(res => {
              if (res.data.Code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this.getAttachmentList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.Msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败，请重试！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    _getExaminationList () {
      examinationService.labList(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: 'cast(Periods.PeriodName as int) desc,Tenders.SortId asc,Tenders.TenderName asc' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.examinationList = res.data.data
            this.total = res.data.pageCount
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _edit () {
      examinationService.edit(this.examinationModel)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getExaminationList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          console.log(err.request.response)
        })
    },
    _getEngineeringTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'tenderEngineeringType' })
        .then(res => {
          if (res.data.code === 1) {
            this.engineeringTypes = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    _getNextUsers (examinationGuid) {
      examinationService.getUsers(examinationGuid)
        .then(res => {
          console.log(res)
          if (res.data.code === 1) {
            let data = res.data.data
            if (data.length) {
              data.forEach(flow => {
                flow.flowGuid = flow.userGuid
                flow.stepName = flow.userName
                flow.value = flow.flowGuid
                flow.label = flow.stepName
                delete flow.userGuid
                delete flow.userName
                if (flow.children && flow.children.length) {
                  flow.children.forEach(user => {
                    user.label = user.staffName
                    user.value = user.userGuid
                  })
                } else {
                  flow.children = []
                }
              })
              this.$refs.submitFormDialog.open()
              this.identityAndUsers = data
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    getUsers () {
      userService.list(new PageInfo({ pageIndex: 1, pageSize: 999, fieldOrder: 'users.sortId' }), { projectGuid: auth.getUserInfo().projectGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.userList = res.data.data
          }
        })
        .catch(err => {
          window.console.log(err)
        })
    },
    _getAttachmentTypes () {
      dictionaryService.list(new PageInfo({ pageSize: config.bigPageSize, pageIndex: 1, fieldOrder: 'sortId' }), { dictionaryKey: 'attachmentType' })
        .then(res => {
          if (res.data.code === 1) {
            this.attachmentTypes = res.data.data
            console.log(this.attachmentTypes)
          } else {
            this.$message({
              type: 'error',
              message: '获取数据失败！'
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    },
    ...mapMutations('examination', {
      setExaminationGuid: 'setExaminationGuid',
      setTenderGuid: 'setTenderGuid',
      setPeriodGuid: 'setPeriodGuid',
      setTenderName: 'setTenderName',
      setPeriodName: 'setPeriodName',
      setExaminationCode: 'setExaminationCode',
      setApprovalUserGuid: 'setApprovalUserGuid',
      setApprovalStatus: 'setApprovalStatus',
      setApprovalIdentityGuid: 'setApprovalIdentityGuid'
    })
  },
  created () {
    this._getExaminationList()
    this.refreshMethod = this._getExaminationList
    this._getAttachmentTypes()
    this._getEngineeringTypes()
    this.getUsers()
  }
}
</script>

<style scoped lang="scss">
.el-cascader {
  width: 100%;
}
img {
  width: 15px;
  height: 15px;
}
</style>
